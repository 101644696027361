




import { Component } from 'vue-property-decorator';
import { ApexOptions } from 'apexcharts';
import ChartBase from '../ChartBase';
import LineChartData from './modelsLine';

@Component
export default class ChartLine extends ChartBase {
  declare chartData: LineChartData;

  options: ApexOptions = {};

  series: any | null = null;

  onInitOrUpdate(): void {
    console.log('init or update');
    this.options = {
      chart: {
        id: '',
      },
      xaxis: {
        categories: this.queryResult.map((x: any) => x[this.chartData.xAxisField]),
      },
      colors: this.chartData.colors && this.chartData.colors.length && this.chartData.colors.some((x) => x) ? this.chartData.colors : undefined,
    };

    this.series = this.chartData.seriesFields.map((x) => ({
      name: x,
      data: this.queryResult.map((y: any) => y[x]),
    }));
  }
}
