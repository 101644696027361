import {
  Vue,
  Component,
  Prop,
  Watch,
} from 'vue-property-decorator';

@Component
export default class ChartBase extends Vue {
  @Prop() chartUUID?: string;

  @Prop({ required: true }) queryResult!: any;

  @Prop({ required: true }) chartData!: any;

  created() {
    this.onInit();
    this.onInitOrUpdate();
  }

  // todo: make watch methods private (or sth..)
  @Watch('queryResult', { deep: true })
  onQueryResultChange(): void {
    console.log('query result change');
    this.onQueryResultUpdated();
    this.onUpdate();
    this.onInitOrUpdate();
  }

  @Watch('chartData', { deep: true })
  onChartDataChange(): void {
    console.log('chart data change');
    this.onChartDataUpdated();
    this.onUpdate();
    this.onInitOrUpdate();
  }

  /* METHODS TO BE OVERRIDEN BY CHILD CLASSES */

  // todo: disable specific rule only
  /* eslint-disable */

  /**
   * Called on creation.
   */
  onInit(): void {}

  /**
   * Called on 'queryResult' update.
   */
  onQueryResultUpdated(): void {}

  /**
   * Called on 'chartData' update.
   */
  onChartDataUpdated(): void {}

  /**
   * Called on 'queryResult' or 'chart' update.
   */
  onUpdate(): void {}

  /**
   * Called on creation or on 'queryResult' or 'chartData' update.
   */
  onInitOrUpdate(): void {}

  /**
   * Called on chart resize by the user.
   */
  onChartResize(): void {} // directly called from parent component

  /* eslint-enable */
}
